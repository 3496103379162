import React from 'react';

import './button_close.css';

interface ButtonCloseProps {
  onClick?: any;
}

const ButtonClose: React.FC<ButtonCloseProps> = (props) => {
  return (
    <div className="button-close">
      <a tabIndex={-1} onClick={props.onClick ? props.onClick : ""}><i className="fas fa-circle fa-inverse"></i></a>
      <a tabIndex={-1} onClick={props.onClick ? props.onClick : ""}><i className="fas fa-times-circle"></i></a>
    </div>
  );
}

export default ButtonClose;