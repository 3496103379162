import React, { FormEvent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import HeaderLogin from './header_login';
import PoliticaPrivacidadeLogin from './politicaprivacidade_login';

import Button from '../../components/button/button';
import ButtonClose from '../../components/button_close/button_close';
import ALink from '../../components/alink/alink';
import Input from '../../components/input/input';

import './login.css';

function Login() {
  const history = useHistory();

  const [toLoginMove, setToLoginMove] = useState('login-static');

  const [toLoginShow, setToLoginShow] = useState(true);
  const [toRecuperarSenhaShow, setToRecuperarSenhaShow] = useState(false);
  const [toAlterarSenhaShow, setToAlterarSenhaShow] = useState(false);
  const [toPoliticaPrivacidadeShow, setToPoliticaPrivacidadeShow] = useState(false);

  const [toUser, setToUser] = useState('');
  const [toPassword, setToPassword] = useState('');
  const [toASSenhaAtual, setToASSenhaAtual] = useState('');
  const [toASNovaSenha, setToASNovaSenha] = useState('');
  const [toASRepitaNovaSenha, setToASRepitaNovaSenha] = useState('');
  const [toRSUser, setToRSUser] = useState('');
  const [toRSEmail, setToRSEmail] = useState('');

  useEffect(() => {
    if (toLoginMove == 'login-static') { 
      const timer = setTimeout(() => {
        setToLoginMove('login-move');
      }, 200);
      return () => clearTimeout(timer);
    }
  });

  async function handleRecuperarSenhaShow() {
    if (toRecuperarSenhaShow == false) { 
      setToRecuperarSenhaShow(true); setToLoginShow(false);
    } else {
      setToRecuperarSenhaShow(false); setToLoginShow(true);
    }
  }

  async function handleAlterarSenhaShow() {
    if (toAlterarSenhaShow == false) { 
      setToAlterarSenhaShow(true); setToLoginShow(false);
    } else {
      setToAlterarSenhaShow(false); setToLoginShow(true);
    }
  }

  async function handlePoliticaPrivacidadeShow() {
    if (toPoliticaPrivacidadeShow == false) { 
      setToPoliticaPrivacidadeShow(true); setToLoginShow(false);
    } else {
      setToPoliticaPrivacidadeShow(false); setToLoginShow(true); 
    }
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    history.push('/Main');
  }

  async function handleSubmitRecuperarSenha(event: FormEvent) {
    event.preventDefault();

    handleRecuperarSenhaShow();
  }

  async function handleSubmitAlterarSenha(event: FormEvent) {
    event.preventDefault();

    history.push('/Main');
  }

  return ( 
    <div id="login-container">
      <div id="login-display" className={`${toLoginMove}`}>
        <div className="wrap">
          <div className="login-content">
            {/* LOGO */}
            <HeaderLogin />
          </div>
          <div className="login-user">

            {/* LOGIN */}
            <div className={ toLoginShow ? ( 'login-form-wrap login-user-show fade-in' ) : ( 'login-form-wrap login-user-hide fade-out' ) }>
              {/* <!-- TABS --> */}
              <div className="login-tabs">
                <h3 className="login-tab"><span></span></h3>
              </div>
              {/* <!-- TABS CONTENT --> */}
              <div className="login-tabs-content">
                {/* <!-- TABS CONTENT LOGIN --> */}
                <div id="login-tab-content">
                  <h3 className="login-title"><span>Login</span></h3>

                  <main>
                  <form onSubmit={handleSubmit} className="login-form">
                    <fieldset>
                      <Input id="txtUser" type="text" model="login" 
                             tabIndex={1} maxLength={50}
                             onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => 
                                        setToUser(event.target.value)}
                             value={toUser} placeholder="USR01, usr@mail.com ou 000.000.000-00" 
                             label="Usuário, E-mail ou CPF" icon="fas fa-user" />

                      <Input id="txtPassword" type="password" model="login" 
                             tabIndex={2} maxLength={8}
                             onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => 
                                        setToPassword(event.target.value)}
                             value={toPassword} placeholder="********" 
                             label="Senha" icon="fas fa-key" />
                    </fieldset>
                    <div className="login-links">
                      <ALink id="lnkRecuperarSenha"
                             tabIndex={3}
                             onClick={handleRecuperarSenhaShow}
                             label="Recuperar Senha" icon="fas fa-envelope fa-space-text" />

                      <ALink id="lnkAlterarSenha"
                             tabIndex={4}
                             onClick={handleAlterarSenhaShow}
                             label="Alterar Senha" icon="fas fa-unlock fa-space-text" />
                    </div>
                    <Button id="btnSubmit" type="submit"
                            tabIndex={5} 
                            label="Login" icon="fas fa-check fa-space-text" />
                  </form>
                  </main>

                </div>
              </div>
              <div className="login-footer">
                <ALink id="lnkPoliticaPrivacidade"
                       tabIndex={-1}
                       onClick={handlePoliticaPrivacidadeShow}
                       label="Política de Privacidade" icon="fas fa-shield-alt fa-space-text" />
              </div>
            </div>

            {/* RECUPERAR SENHA */}
            <div className={ toRecuperarSenhaShow ? ( 'login-recuperarsenha-show fade-in' ) : ( 'login-recuperarsenha-hide fade-out' ) }>
              <div className="login-button-close">
                <ButtonClose onClick={handleRecuperarSenhaShow} />
              </div>

              <div className="login-tab-recuperarsenha">
                  <h3 className="login-title"><span>Recuperar Senha</span></h3>

                  <main>
                  <form onSubmit={handleSubmitRecuperarSenha} className="login-form">
                    <fieldset>
                      <Input id="txtRSUser" type="text" model="login" 
                             tabIndex={1} maxLength={50}
                             onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => 
                                        setToRSUser(event.target.value)}
                             value={toRSUser} placeholder="USR01 ou 000.000.000-00" 
                             label="Usuário ou CPF" icon="fas fa-user" />

                      <Input id="txtRSEmail" type="text" model="login" 
                             tabIndex={2} maxLength={100}
                             onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => 
                                        setToRSEmail(event.target.value)}
                             value={toRSEmail} placeholder="usr@mail.com" 
                             label="E-mail" icon="fas fa-envelope" />
                    </fieldset>
                    <Button id="btnRSSubmit" type="submit"
                            tabIndex={3} 
                            label="Confirmar" icon="fas fa-check fa-space-text" />
                  </form>
                  </main>

                </div>
            </div>

            {/* ALTERAR SENHA */}
            <div className={ toAlterarSenhaShow ? ( 'login-alterarsenha-show fade-in' ) : ( 'login-alterarsenha-hide fade-out' ) }>
              <div className="login-button-close">
                <ButtonClose onClick={handleAlterarSenhaShow} />
              </div>

              <div className="login-tab-alterarsenha">
                  <h3 className="login-title"><span>Alterar Senha</span></h3>

                  <main>
                  <form onSubmit={handleSubmitAlterarSenha} className="login-form">
                    <fieldset>
                      <Input id="txtASSenhaAtual" type="password" model="login" 
                             tabIndex={1} maxLength={8}
                             onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => 
                                        setToASSenhaAtual(event.target.value)}
                             value={toASSenhaAtual} placeholder="********" 
                             label="Senha Atual" icon="fas fa-key" />

                      <Input id="txtASNovaSenha" type="password" model="login" 
                             tabIndex={2} maxLength={8}
                             onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => 
                                        setToASNovaSenha(event.target.value)}
                             value={toASNovaSenha} placeholder="********" 
                             label="Nova Senha" icon="fas fa-key" />

                      <Input id="txtASRepitaNovaSenha" type="password" model="login" 
                             tabIndex={3} maxLength={8}
                             onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => 
                                        setToASRepitaNovaSenha(event.target.value)}
                             value={toASRepitaNovaSenha} placeholder="********" 
                             label="Repita Nova Senha" icon="fas fa-key" />
                    </fieldset>
                    <Button id="btnASSubmit" type="submit"
                            tabIndex={4} 
                            label="Salvar" icon="fas fa-check fa-space-text" />
                  </form>
                  </main>

                </div>
            </div>

            {/* POLITICA PRIVACIDADE */}
            <div className={ toPoliticaPrivacidadeShow ? ( 'login-politicaprivacidade-show fade-in' ) : ( 'login-politicaprivacidade-hide fade-out' ) }>
              <div className="login-button-close-politicaprivacidade">
                <ButtonClose onClick={handlePoliticaPrivacidadeShow} />
              </div>

              <PoliticaPrivacidadeLogin />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
