import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import HeaderPage from '../../components/header_page/header_page';
import FooterPage from '../../components/footer_page/footer_page';

import './main.css';

function Main() {
  return ( 
    <div id="main-container">
      <div className="wrap">
        <div className="main-base">
          <div className="main-header">
            <HeaderPage></HeaderPage>
          </div>
          <div id="main-wrap" className="main-wrap">
            <div></div>
            <div className="main-content">
            <div id="scrollToTop"></div>

            <div className="main-menu">
              <Link to="/RemuneracaoFuncionarios" className="main-menu-link">
                <div id="main-menu-card">
                  <div className="main-menu-card-id">
                    <i className="fas fa-usd-circle"></i>
                  </div>
                  <div className="main-menu-card-content">
                    <strong>Remuneração Variável</strong>
                    <p>
                      Acompanhe todos os dados em detalhes<br></br>
                      As informações disponibilizadas correspondem a todas 
                      as remunerações variáveis calculadas em todos os cronogramas
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            </div>
            <div></div>
            <div className="main-footer">
              <FooterPage scrolltotop="main-wrap"></FooterPage>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
