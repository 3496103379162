import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';

import './splash_screen.css';
import './animation.css';

import logoImg1 from '../../assets/images/img_logo_d.png';
import logoImg2 from '../../assets/images/img_logo_a.png';
import logoImg3 from '../../assets/images/img_logo_t.png';
import logoImg4 from '../../assets/images/img_logo_z.png';
import logoImg5 from '../../assets/images/img_logo_consultoria.png';

function SplashScreen() {
  const [toRedirect, setToRedirect] = useState(false);
  const [toSplashMove, setToSplashMove] = useState('splash-static');

  useEffect(() => {
    // console.log(toRedirect);
    if (!toRedirect) { 
      const timer = setTimeout(() => {
        setToSplashMove('splash-move');
        setToRedirect(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
    if (toRedirect) { 
      const timer = setTimeout(() => {
        setToSplashMove('splash-static');
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  if (toRedirect && toSplashMove == 'splash-static' ) {
    return ( <Redirect to={'/Login'} /> ) 
  }
  
  return ( 
    <Link to="/Login">
      <div id="splash-container">
        <div id="splash-display" className={`${toSplashMove}`}>
          <div className="splash-animation">
            <h1>
              <span><img src={logoImg1} alt="D" className="splash-d1" /></span>
              <span><img src={logoImg2} alt="a" className="splash-a1" /></span>
              <span><img src={logoImg3} alt="T" className="splash-t1" /></span>
              <span><img src={logoImg2} alt="a" className="splash-a2" /></span>
              <span><img src={logoImg4} alt="Z" className="splash-z1" /></span>
            </h1>
            <h2>
              <span><img src={logoImg5} alt="consultoria" className="splash-consultoria1" /></span>
            </h2>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default SplashScreen;
