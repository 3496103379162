import React from 'react';

import './button.css';

interface ButtonProps {
  id: string;
  type?: any;
  onClick?: any;
  tabIndex?: number;
  icon?: string;
  label?: string;
}

const Button: React.FC<ButtonProps> = (props) => {
  return (
    <button id={props.id} 
            type={props.type ? props.type : "button"} 
            onClick={props.onClick ? props.onClick : undefined} 
            tabIndex={props.tabIndex ? props.tabIndex : 0}>
      <span>
        {props.icon ? <i className={props.icon}></i> : ""}
        {props.label ? props.label : ""}
      </span>
    </button>
  );
}

export default Button;