import React, { FormEvent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import HeaderPage from '../../components/header_page/header_page';
import FooterPage from '../../components/footer_page/footer_page';
import Search from '../../components/search/search';

import './remuneracao_funcionarios.css';

function RemuneracaoFuncionarios() {
  const history = useHistory();

  const [toSearch, setToSearch] = useState('');

  return ( 
    <div id="remuneracao-funcionarios-container">
      <div className="wrap">
        <div className="remuneracao-funcionarios-base">
          <div className="remuneracao-funcionarios-header">
            <HeaderPage model="navigation"
                        title="Funcionários"></HeaderPage>
          </div>
          <div id="remuneracao-funcionarios-wrap" className="remuneracao-funcionarios-wrap">
            <div className="remuneracao-funcionarios-content">
              <div id="scrollToTop"></div>

              <div className="remuneracao-funcionarios-search">
                <Search id="txtSearch" 
                        onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => 
                                  setToSearch(event.target.value)} 
                        value={toSearch} />
              </div>

              <div>
                <table>
                  <tr>
                    <td className="remuneracao-funcionarios-table-td-laterais"></td>
                    <td>
                      <Link to="/Remuneracao" className="remuneracao-funcionarios-content-link">
                      <div className="remuneracao-funcionarios-table-linha1">
                        <div className="remuneracao-funcionarios-table-nome">
                          <p>NOME</p>
                          <p><strong>CARLOS EDUARDO FERREIRA</strong></p>
                        </div>
                        <div className="remuneracao-funcionarios-table-matricula">
                          <p>MATRÍCULA</p>
                          <p><strong>25674</strong></p>
                        </div>
                      </div>
                      <div className="remuneracao-funcionarios-table-linha2">
                        <div className="remuneracao-funcionarios-table-empresa">
                          <p>EMPRESA</p>
                          <p><strong>FIAT_SP_PIN</strong></p>
                        </div>
                        <div className="remuneracao-funcionarios-table-funcao">
                          <p>FUNÇÃO</p>
                          <p><strong>VENDEDOR VN</strong></p>
                        </div>
                      </div>
                      </Link>
                    </td>
                    <td className="remuneracao-funcionarios-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-funcionarios-table-td-laterais"></td>
                    <td>
                      <Link to="/Remuneracao" className="remuneracao-funcionarios-content-link">
                      <div className="remuneracao-funcionarios-table-linha1">
                        <div className="remuneracao-funcionarios-table-nome">
                          <p>NOME</p>
                          <p><strong>FERNANDA OLIVEIRA SALGADO</strong></p>
                        </div>
                        <div className="remuneracao-funcionarios-table-matricula">
                          <p>MATRÍCULA</p>
                          <p><strong>1272</strong></p>
                        </div>
                      </div>
                      <div className="remuneracao-funcionarios-table-linha2">
                        <div className="remuneracao-funcionarios-table-empresa">
                          <p>EMPRESA</p>
                          <p><strong>FIAT_SP_GUA</strong></p>
                        </div>
                        <div className="remuneracao-funcionarios-table-funcao">
                          <p>FUNÇÃO</p>
                          <p><strong>VENDEDOR SN</strong></p>
                        </div>
                      </div>
                      </Link>
                    </td>
                    <td className="remuneracao-funcionarios-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-funcionarios-table-td-laterais"></td>
                    <td>
                      <Link to="/Remuneracao" className="remuneracao-funcionarios-content-link">
                      <div className="remuneracao-funcionarios-table-linha1">
                        <div className="remuneracao-funcionarios-table-nome">
                          <p>NOME</p>
                          <p><strong>GUILHERME ALMEIDA DE CASTRO</strong></p>
                        </div>
                        <div className="remuneracao-funcionarios-table-matricula">
                          <p>MATRÍCULA</p>
                          <p><strong>87554</strong></p>
                        </div>
                      </div>
                      <div className="remuneracao-funcionarios-table-linha2">
                        <div className="remuneracao-funcionarios-table-empresa">
                          <p>EMPRESA</p>
                          <p><strong>FIAT_SP_BUT</strong></p>
                        </div>
                        <div className="remuneracao-funcionarios-table-funcao">
                          <p>FUNÇÃO</p>
                          <p><strong>MECÂNICO</strong></p>
                        </div>
                      </div>
                      </Link>
                    </td>
                    <td className="remuneracao-funcionarios-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-funcionarios-table-td-laterais"></td>
                    <td>
                      <Link to="/Remuneracao" className="remuneracao-funcionarios-content-link">
                      <div className="remuneracao-funcionarios-table-linha1">
                        <div className="remuneracao-funcionarios-table-nome">
                          <p>NOME</p>
                          <p><strong>JOÃO CARLOS ALBERTO TELLES</strong></p>
                        </div>
                        <div className="remuneracao-funcionarios-table-matricula">
                          <p>MATRÍCULA</p>
                          <p><strong>3267</strong></p>
                        </div>
                      </div>
                      <div className="remuneracao-funcionarios-table-linha2">
                        <div className="remuneracao-funcionarios-table-empresa">
                          <p>EMPRESA</p>
                          <p><strong>FIAT_SP_BUT</strong></p>
                        </div>
                        <div className="remuneracao-funcionarios-table-funcao">
                          <p>FUNÇÃO</p>
                          <p><strong>VENDEDOR VN</strong></p>
                        </div>
                      </div>
                      </Link>
                    </td>
                    <td className="remuneracao-funcionarios-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-funcionarios-table-td-laterais"></td>
                    <td>
                      <Link to="/Remuneracao" className="remuneracao-funcionarios-content-link">
                      <div className="remuneracao-funcionarios-table-linha1">
                        <div className="remuneracao-funcionarios-table-nome">
                          <p>NOME</p>
                          <p><strong>MARIA TEREZA CASTRO RAMOS</strong></p>
                        </div>
                        <div className="remuneracao-funcionarios-table-matricula">
                          <p>MATRÍCULA</p>
                          <p><strong>33227</strong></p>
                        </div>
                      </div>
                      <div className="remuneracao-funcionarios-table-linha2">
                        <div className="remuneracao-funcionarios-table-empresa">
                          <p>EMPRESA</p>
                          <p><strong>RENAULT_SP_IPI</strong></p>
                        </div>
                        <div className="remuneracao-funcionarios-table-funcao">
                          <p>FUNÇÃO</p>
                          <p><strong>VENDEDOR SN</strong></p>
                        </div>
                      </div>
                      </Link>
                    </td>
                    <td className="remuneracao-funcionarios-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-funcionarios-table-td-laterais"></td>
                    <td>
                      <Link to="/Remuneracao" className="remuneracao-funcionarios-content-link">
                      <div className="remuneracao-funcionarios-table-linha1">
                        <div className="remuneracao-funcionarios-table-nome">
                          <p>NOME</p>
                          <p><strong>PAULO RAMOS TEIXEIRA</strong></p>
                        </div>
                        <div className="remuneracao-funcionarios-table-matricula">
                          <p>MATRÍCULA</p>
                          <p><strong>56438</strong></p>
                        </div>
                      </div>
                      <div className="remuneracao-funcionarios-table-linha2">
                        <div className="remuneracao-funcionarios-table-empresa">
                          <p>EMPRESA</p>
                          <p><strong>RENAULT_SP_IPI</strong></p>
                        </div>
                        <div className="remuneracao-funcionarios-table-funcao">
                          <p>FUNÇÃO</p>
                          <p><strong>PINTOR</strong></p>
                        </div>
                      </div>
                      </Link>
                    </td>
                    <td className="remuneracao-funcionarios-table-td-laterais"></td>
                  </tr>
                </table>
              </div>

              {/* X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br> */}
            </div>
            <div className="remuneracao-funcionarios-footer">
              <FooterPage scrolltotop="remuneracao-funcionarios-wrap"></FooterPage>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
  export default RemuneracaoFuncionarios;