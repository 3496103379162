import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import HeaderPage from '../../components/header_page/header_page';
import FooterPage from '../../components/footer_page/footer_page';
import Search from '../../components/search/search';

import './remuneracao.css';

function Remuneracao() {
  const history = useHistory();

  const [toSearch, setToSearch] = useState('');

  return ( 
    <div id="remuneracao-container">
      <div className="wrap">
        <div className="remuneracao-base">
          <div className="remuneracao-header">
            <HeaderPage model="navigation" 
                        title="Remuneração Variável"></HeaderPage>
          </div>
          <div id="remuneracao-wrap" className="remuneracao-wrap">
            <div className="remuneracao-content">
              <div id="scrollToTop"></div>

              <div className="remuneracao-search">
                <Search id="txtSearch" 
                        onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => 
                                  setToSearch(event.target.value)} 
                        value={toSearch} />
              </div>

              <div>
                <table>
                  <tr>
                    <td className="remuneracao-table-td-laterais"></td>
                    <td className="remuneracao-table-td-mes">
                      <h2>Dezembro</h2>
                    </td>
                    <td className="remuneracao-table-td-ano">
                      <h2>2020</h2>
                    </td>
                    <td className="remuneracao-table-td-valor">
                      <h2>3.920,00</h2>
                    </td>
                    <td className="remuneracao-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-table-td-laterais"></td>
                    <td className="remuneracao-table-td-mes">
                      <h2>Novembro</h2>
                    </td>
                    <td className="remuneracao-table-td-ano">
                      <h2>2020</h2>
                    </td>
                    <td className="remuneracao-table-td-valor">
                      <h2>2.550,50</h2>
                    </td>
                    <td className="remuneracao-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-table-td-laterais"></td>
                    <td className="remuneracao-table-td-mes">
                      <h2>Outubro</h2>
                    </td>
                    <td className="remuneracao-table-td-ano">
                      <h2>2020</h2>
                    </td>
                    <td className="remuneracao-table-td-valor">
                      <h2>2.345,50</h2>
                    </td>
                    <td className="remuneracao-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-table-td-laterais"></td>
                    <td className="remuneracao-table-td-mes">
                      <h2>Setembro</h2>
                    </td>
                    <td className="remuneracao-table-td-ano">
                      <h2>2020</h2>
                    </td>
                    <td className="remuneracao-table-td-valor">
                      <h2>3.445,00</h2>
                    </td>
                    <td className="remuneracao-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-table-td-laterais"></td>
                    <td className="remuneracao-table-td-mes">
                      <h2>Agosto</h2>
                    </td>
                    <td className="remuneracao-table-td-ano">
                      <h2>2020</h2>
                    </td>
                    <td className="remuneracao-table-td-valor">
                      <h2>1.850,50</h2>
                    </td>
                    <td className="remuneracao-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-table-td-laterais"></td>
                    <td className="remuneracao-table-td-mes">
                      <h2>Julho</h2>
                    </td>
                    <td className="remuneracao-table-td-ano">
                      <h2>2020</h2>
                    </td>
                    <td className="remuneracao-table-td-valor">
                      <h2>2.880,00</h2>
                    </td>
                    <td className="remuneracao-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-table-td-laterais"></td>
                    <td className="remuneracao-table-td-mes">
                      <h2>Junho</h2>
                    </td>
                    <td className="remuneracao-table-td-ano">
                      <h2>2020</h2>
                    </td>
                    <td className="remuneracao-table-td-valor">
                      <h2>4.125,50</h2>
                    </td>
                    <td className="remuneracao-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-table-td-laterais"></td>
                    <td className="remuneracao-table-td-mes">
                      <h2>Maio</h2>
                    </td>
                    <td className="remuneracao-table-td-ano">
                      <h2>2020</h2>
                    </td>
                    <td className="remuneracao-table-td-valor">
                      <h2>3.265,50</h2>
                    </td>
                    <td className="remuneracao-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-table-td-laterais"></td>
                    <td className="remuneracao-table-td-mes">
                      <h2>Abril</h2>
                    </td>
                    <td className="remuneracao-table-td-ano">
                      <h2>2020</h2>
                    </td>
                    <td className="remuneracao-table-td-valor">
                      <h2>1.775,00</h2>
                    </td>
                    <td className="remuneracao-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-table-td-laterais"></td>
                    <td className="remuneracao-table-td-mes">
                      <h2>Março</h2>
                    </td>
                    <td className="remuneracao-table-td-ano">
                      <h2>2020</h2>
                    </td>
                    <td className="remuneracao-table-td-valor">
                      <h2>2.375,00</h2>
                    </td>
                    <td className="remuneracao-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-table-td-laterais"></td>
                    <td className="remuneracao-table-td-mes">
                      <h2>Fevereiro</h2>
                    </td>
                    <td className="remuneracao-table-td-ano">
                      <h2>2020</h2>
                    </td>
                    <td className="remuneracao-table-td-valor">
                      <h2>2.250,00</h2>
                    </td>
                    <td className="remuneracao-table-td-laterais"></td>
                  </tr>
                  <tr>
                    <td className="remuneracao-table-td-laterais"></td>
                    <td className="remuneracao-table-td-mes">
                      <h2>Janeiro</h2>
                    </td>
                    <td className="remuneracao-table-td-ano">
                      <h2>2020</h2>
                    </td>
                    <td className="remuneracao-table-td-valor">
                      <h2>1.640,50</h2>
                    </td>
                    <td className="remuneracao-table-td-laterais"></td>
                  </tr>
                </table>
              </div>

              {/* X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br>
              X2<br></br>X2<br></br>X2<br></br>X2<br></br> */}
            </div>
            <div className="remuneracao-footer">
              <FooterPage scrolltotop="remuneracao-wrap"></FooterPage>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Remuneracao;