import React from 'react';
import { Link } from 'react-router-dom';

import './app_react.css';

import logo from '../../assets/images/logo_react.svg';

function AppReact() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div>
          <p>
            TESTE 11/02/2021 às 15:44h
          </p>
          <p>
            <Link to="/">
              Splash Screen
            </Link>
          </p>
        </div>
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default AppReact;
