import React, { FormEvent, useState, useEffect } from 'react';

import './search.css';

interface SearchProps {
  id: string;
  onChange?: any;
  tabIndex?: number;
  maxLength?: number;
  value?: any;
  placeholder?: string;
}

const Search: React.FC<SearchProps> = (props) => {

  const [toValue, setToValue] = useState('');

  const [toIconClean, setToIconClean] = useState(false);

  useEffect(() => {
    setToValue(props.value);
    if (props.value != '') { 
      setToIconClean(true);
    } else {
      setToIconClean(false);
    };
    }, [props.value])

  async function handleIconClean(e:any) {
    setToValue('');
    setToIconClean(false);
  }

  return (
    <div className="search-base">
      <div className="search-icon-search">
        <i className="fas fa-search" aria-hidden="true"></i>
      </div>
      <div className="search-input">
        <input id={props.id} 
                type="text"
                onChange={props.onChange ? props.onChange : undefined}
                maxLength={props.maxLength ? props.maxLength : -1}
                tabIndex={props.tabIndex ? props.tabIndex : 0}
                value={toValue ? toValue : ""} 
                placeholder={props.placeholder ? props.placeholder : ""} />
      </div>
      <div className={ toIconClean ? ( 'search-icon-clean' ) : ( 'search-icon-clean-hide' ) }>
        <a onClick={handleIconClean}>
          <i className="fas fa-times" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  );
}

export default Search;