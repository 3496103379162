import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import App from './App';
import AppReact from './pages/app_react/app_react';

import SplashScreen from './pages/splash_screen/splash_screen';
import Login from './pages/login/login';
import Main from './pages/main/main';

import Remuneracao from './pages/remuneracao/remuneracao';
import RemuneracaoFuncionarios from './pages/remuneracao/remuneracao_funcionarios';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={SplashScreen} />
        <Route path="/Login" component={Login} />
        <Route path="/Main" component={Main} />

        <Route path="/Remuneracao" component={Remuneracao} />
        <Route path="/RemuneracaoFuncionarios" component={RemuneracaoFuncionarios} />
        
        <Route path="/AppReact" component={AppReact} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;