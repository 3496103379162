import React from 'react';

import './input.css';

interface InputProps {
  id: string;
  type?: any;
  onChange?: any;
  tabIndex?: number;
  maxLength?: number;
  value?: any;
  placeholder?: string;
  icon?: string;
  label?: string;
  model?: string;
}

const Input: React.FC<InputProps> = (props) => {
  return (
    <div>
    {(props.model == undefined || props.model == "") && 
     (props.type == undefined || props.type == "" || props.type == "text" || props.type == "password") ? 
    <div className="input-base">
      <label htmlFor={props.id}>{props.label ? props.label : ""}</label>
      <input id={props.id} 
            type={props.type ? props.type : "text"}
            onChange={props.onChange ? props.onChange : undefined}
            maxLength={props.maxLength ? props.maxLength : -1}
            tabIndex={props.tabIndex ? props.tabIndex : 0}
            value={props.value ? props.value : ""} 
            placeholder={props.placeholder ? props.placeholder : ""} />
      {props.icon ? <i className={props.icon} aria-hidden="true"></i> : ""}
    </div>
    : ""}
    {(props.model == "login") && 
     (props.type == undefined || props.type == "" || props.type == "text" || props.type == "password") ? 
    <div className="input-login">
      <label htmlFor={props.id}>{props.label ? props.label : ""}</label>
      <input id={props.id} 
            type={props.type ? props.type : "text"}
            onChange={props.onChange ? props.onChange : undefined}
            maxLength={props.maxLength ? props.maxLength : -1}
            tabIndex={props.tabIndex ? props.tabIndex : 0}
            value={props.value ? props.value : ""} 
            placeholder={props.placeholder ? props.placeholder : ""} />
      {props.icon ? <i className={props.icon} aria-hidden="true"></i> : ""}
    </div>
    : ""}
    </div>
  );
}

export default Input;