import React from 'react';

import './politicaprivacidade_login.css';

interface PoliticaPrivacidadeProps {
  title?: string;
}

const PoliticaPrivacidade: React.FC<PoliticaPrivacidadeProps> = (props) => {
  return (
    <div className="politicaprivacidade-login">
      <h4>POLÍTICA DE PRIVACIDADE</h4>
      <h5>Como coletamos dados pessoais</h5>
      <p>
        Os dados presentes em nosso site, não são diretamente coletados pelo mesmo. 
        São inseridos ao nosso sistema de controle interno via importação e/ou cadastramento realizado por responsáveis pelas informações. 
        No caso, empresas e/ou instituições que tratam diretamente com os proprietários desses dados e que por realizar trabalhos e/ou adquirir serviços 
        necessitam dessas informações para poder receber e/ou realizar pagamentos, acompanhar processos, definir diretrizes, entre outros.
      </p>

      <h5>Dados de navegação</h5>
      <p>
        Os dados de navegação registram apenas informações para melhorar a própria navegação, 
        pois são utilizados para apresentar atalhos e facilitar os acessos às telas do próprio site.
      </p>

      <h5>Onde armazenamos, tratamos e transferimos os dados</h5>
      <p>
        Os dados podem ser transferidos, armazenados em bancos de dados e tratados no Brasil e/ou no exterior, 
        respeitando procedimentos de segurança e confidencialidade.
      </p>

      <h5>Como usamos os dados</h5>
      <p>
        Realizamos um processo de reunião, organização e análise dos dados. 
        Também consiste em transformar esta grande quantidade de dados brutos em informação útil para análise e tomadas de decisões.
      </p>

      <h5>Com quem compartilhamos os dados</h5>
      <p>
        Os dados não são compartilhados, são tratados única e exclusivamente em nosso sistema de controle interno.
      </p>

      <h5>Como protegemos os dados</h5>
      <p>
        Todos os controles são continuamente revisados para acompanhar e reagir ao contexto de ameaças na Internet. 
        Ainda assim, não é possível garantir que seja completamente inviolável.
      </p>
    </div>
  );
}

export default PoliticaPrivacidade;