import React from 'react';
import { Link } from 'react-router-dom';

import './header_login.css';

import logoImg from '../../assets/images/img_logo.png';
import logoImgLarge from '../../assets/images/img_logo_large.png';
import idLogoImg from '../../assets/images/img_id_logo_inverso.png';

interface HeaderLoginProps {
  title?: string;
}

const HeaderLogin: React.FC<HeaderLoginProps> = (props) => {
  return (
    <div className="header-login-logo">
      <img id="header-login-logo" src={logoImgLarge} alt="Logo" />
      <img id="header-login-logo-small" src={logoImg} alt="Logo" />
      <span className="header-login-id-logo">
        <img id="header-login-id-logo" src={idLogoImg} alt="ID" />
      </span>
    </div>
  );
}

export default HeaderLogin;