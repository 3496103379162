import React from 'react';

import Routes from './routes';

import './assets/styles/variables.css'
import './assets/styles/global.css'
import './assets/styles/fade.css'
import './assets/styles/fonts.css'
import './assets/styles/fontawesome-all.min.css'

function App() {
  return (
    <Routes />
  );
}

export default App;