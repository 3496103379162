import React from 'react';

import './alink.css';

interface ALinkProps {
  id: string;
  href?: string;
  target?: string;
  onClick?: any;
  tabIndex?: number;
  icon?: string;
  label?: string;
}

const ALink: React.FC<ALinkProps> = (props) => {
  return (
    <div>
    {(props.href == undefined || props.href == "") ? 
    <p><a id={props.id}
          tabIndex={props.tabIndex ? props.tabIndex : 0} 
          onClick={props.onClick ? props.onClick : ""}>
        <span>
          {props.icon ? <i className={props.icon}></i> : ""}
          {props.label ? props.label : ""}
        </span>
    </a></p>
    : ""}
    {(props.href != undefined && props.href != "") ? 
    <p><a id={props.id}
          tabIndex={props.tabIndex ? props.tabIndex : 0} 
          href={props.href ? props.href : ""}
          target={props.target ? props.target : ""}>
        <span>
          {props.icon ? <i className={props.icon}></i> : ""}
          {props.label ? props.label : ""}
        </span>
    </a></p>
    : ""}
    </div>
  );
}

export default ALink;