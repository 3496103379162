import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import './header_page.css';

import logoImg from '../../assets/images/img_logo.png';
import idLogoImg from '../../assets/images/img_id_logo_inverso.png';

interface HeaderPageProps {
  title?: string;
  user?: string;
  model?: string;
}

const HeaderPage: React.FC<HeaderPageProps> = (props) => {
  const { goBack } = useHistory();

  return (
    <div className="header">
    {(props.model == undefined || props.model == "") ?
    <header className="header-page">
      <div className="header-page-logo">
        <Link to="/Main">
          <div><img src={logoImg} alt="Logo" /></div>
        </Link>
        <Link to="/Main">
          <div><img src={idLogoImg} alt="ID" /></div>
        </Link>
      </div>

      <div className="header-page-content">
        <strong>{props.title ? props.title : "Cálculo de Comissões de Vendas"}</strong>

        <p>{props.user ? props.user : "USUARIO LOGADO"}</p>
      </div>
    </header>
    : ""}
    {(props.model == "navigation") ?
    <header className="header-page-navigation">
      <div className="header-page-navigation-content">
        <div className="header-page-navigation-back">
          <a onClick={goBack}>
            <i className="fas fa-arrow-left fa-space-text"><i className="fa-space-text"></i></i>
          </a>
        </div>
        <div>
          <strong>{props.title ? props.title : ""}</strong>
        </div>
      </div>

      <div className="header-page-navigation-home">
        <Link to="/Main">
          <i className="fas fa-home"></i> 
        </Link>
      </div>
    </header>
    : ""}
    </div>
  );
}

export default HeaderPage;