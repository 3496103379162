import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import './footer_page.css';

import logoImgIcon from '../../assets/images/img_logo_icon.png';

interface FooterPageProps {
  title?: string;
  version?: string;
  scrolltotop?: string;
}

const FooterPage: React.FC<FooterPageProps> = (props) => {
  const history = useHistory();
  
  const [toScrollToTopShow, setToScrollToTopShow] = useState(false);
  const scrolltotop = (props.scrolltotop != "" && props.scrolltotop != undefined) 
                      ? props.scrolltotop : ""
  
  useEffect(() => {
    componentDidMount();
    window.addEventListener('resize', componentDidMount)
  });

  function componentDidMount() {
    if (scrolltotop != "") {
      const desc = (document.body.scrollWidth > 635) ? (80 + (20 * 2)) : 80
      const element = document.body.getElementsByClassName(scrolltotop);
      var scrollHeightx = 0;
      try {
        scrollHeightx = element.item(0).scrollHeight;
      } catch {}
      const hasOverflow = (scrollHeightx + (0)) > (window.innerHeight - (desc));
      // console.log(hasOverflow + " " + (element.item(0).scrollHeight + (0)) + " " + (window.innerHeight - (desc)));
      // console.log(scrollHeight + " " + document.body.scrollWidth);
      // console.log(element);
      if (hasOverflow) { 
        setToScrollToTopShow(true); 
      } else {
        setToScrollToTopShow(false); 
      }
    }
  }

  return (
    <footer className="footer-page">
        <div className="footer-page-logo">
          <a href="http://www.dataz-consultoria.com.br/" target="_blank">
            <div><img src={logoImgIcon} alt="Logo" title="DaTa-Z Consultoria" /></div>
          </a>
        </div>

        <div className="footer-page-content">
          <strong>{props.title ? props.title : "DaTa-Z | Cálculo de Comissões de Vendas"}</strong>
          <p>{props.version ? props.version : "Copyright © 2020-2021 | Vr.: 1.0.2021.01"}</p>
        </div>

        <div className="footer-page-top">
          <a href="#scrollToTop"  
             className={ toScrollToTopShow ? ( 'scrollToTop scrollToTop-Show' ) : ( 'scrollToTop scrollToTop-Hide' ) }>
            <i className="far fa-arrow-circle-up"></i> 
          </a>
        </div>
    </footer>
  );
}

export default FooterPage;